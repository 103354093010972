import api from '@/api/stream-events';

const streamEvents = {
  namespaced: true,

  actions: {
    async getComparedPeriods(_, params) {
      try {
        const { data, success, message } = await api.getComparedPeriods(params);
        if (success) {
          return { success, data };
        } else {
          return { success, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async getGeolocationData(_, params) {
      try {
        const { data, success, message } = await api.getGeolocationData(params);
        if (success) {
          return { success, data };
        } else {
          return { success, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async getHoursData(_, params) {
      try {
        const { data, success, message } = await api.getHoursData(params);
        if (success) {
          return { success, data };
        } else {
          return { success, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async getPodcastsViews(_, params) {
      try {
        const { data, success, message } = await api.getPodcastsViews(params);
        if (success) {
          return { success, data };
        } else {
          return { success, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async getEpisodesViews(_, params) {
      try {
        const { data, success, message } = await api.getEpisodesViews(params);
        if (success) {
          return { success, data };
        } else {
          return { success, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async getSourcesViews(_, params) {
      try {
        const { data, success, message } = await api.getSourcesViews(params);
        if (success) {
          return { success, data };
        } else {
          return { success, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },
  },
};

function errorResponse(error, message = "There's been an error, please try again later") {
  console.error(error);
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default streamEvents;
