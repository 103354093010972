import api from '@/api';

const payments = {
  namespaced: true,

  state: {
    clientSecret: null,
    paymentMethod: null,
  },

  getters: {
    paymentMethod: state => state.paymentMethod,
    hasPaymentMethod: state => state.paymentMethod && state.paymentMethod.brand != null,
  },

  actions: {
    async getClientSecret({ commit }) {
      const response = await api.post('/payment-methods/setup-intent');

      const { success, data, message } = response.data;
      if (success) {
        commit('setClientSecret', data);
      }

      return { message, success };
    },

    async addPaymentMethodId(_, id) {
      const response = await api.post(`/payment-methods`, id);
      const { success, data, message } = response.data;
      return { message, success, data };
    },

    async updatePaymentMethodId(_, id) {
      const response = await api.put(`/payment-methods`, id);
      const { success, data, message } = response.data;
      return { message, success, data };
    },

    async fetchPaymentMethod({ commit }) {
      const response = await api.get('/payment-methods');
      const { success, data } = response.data;
      if (success) {
        commit('setPaymentMethod', data);
      }
      return { success };
    },
  },

  mutations: {
    setClientSecret(state, data) {
      state.clientSecret = data;
    },

    setPaymentMethod(state, data) {
      state.paymentMethod = data;
    },
  },
};

export default payments;
