import API from '@/api';

async function getComparedPeriods(params) {
  const response = await API.get(`/stream-events/compared-periods`, { params });
  return response.data;
}

async function getGeolocationData(params) {
  const response = await API.get(`/stream-events/geolocation-data`, { params });
  return response.data;
}

async function getHoursData(params) {
  const response = await API.get(`/stream-events/hours-data`, { params });
  return response.data;
}

async function getPodcastsViews(params) {
  const response = await API.get(`/stream-events/podcasts-views`, { params });
  return response.data;
}

async function getEpisodesViews(params) {
  const response = await API.get(`/stream-events/episodes-views`, { params });
  return response.data;
}

async function getSourcesViews(params) {
  const response = await API.get(`/stream-events/sources-views`, { params });
  return response.data;
}

export default {
  getComparedPeriods,
  getGeolocationData,
  getHoursData,
  getPodcastsViews,
  getEpisodesViews,
  getSourcesViews,
};
