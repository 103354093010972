const routes = [
  {
    path: '/advertisements',
    name: 'Advertisements',
    component: () => import(/* webpackChunkName: "advertisements" */ '../views/Advertisements.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/advertisements/:advertisementId',
    name: 'Advertisement',
    component: () => import(/* webpackChunkName: "advertisement" */ '../views/Advertisement.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
