import api from '@/api';

const tags = {
  namespaced: true,

  state: {
    items: [],
  },

  actions: {
    async fetch({ commit }) {
      try {
        const { data, success, message } = await api.get('/tags');
        if (success) {
          commit('SET_TAGS', data);
        }
        return { success, message, data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },
  },

  mutations: {
    SET_TAGS(state, data) {
      state.items = data;
    },
  },
};

function errorResponse(error, message) {
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default tags;
