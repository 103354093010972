import axios from 'axios';
import store from '@/store';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  withCredentials: true,
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (error.response.status === 503) {
      router.push('/mantainance');
    }
    if (error.response.status == 303) {
      router.push({ name: 'TwoFactorAuthentication' });
    }
    if (error.response.status == 401) {
      store.commit('user/LOGOUT');
      router.push({ name: 'SignIn' });
    }
    if (error.response.status == 403) {
      let data = error.response.data.data;
      router.push({ name: data.code == 'verify-email' ? 'NeedToVerifyEmail' : 'Forbidden' });
    }
    if (error.response.status == 404) {
      router.push({ name: 'NotFound' });
    }

    return Promise.reject(error);
  }
);

export default instance;
