import api from '@/api/episodes';
import { selectProps } from '@/utils/helpers';

const user = {
  namespaced: true,

  state: {
    advertisableEpisodes: [],
    activeEpisode: null,
    selectedSlot: null,
    pagination: null,
  },

  getters: {
    advertisableEpisodes: state => {
      return state.advertisableEpisodes;
    },
    activeEpisode: state => {
      return state.activeEpisode;
    },
    episodeDuration: state => {
      return state.activeEpisode.duration_in_seconds;
    },
    activeEpisodeSlots: state => {
      return state.activeEpisode.slots.map(selectProps('time_position', 'limit_of_ads', 'price'));
    },
    pagination: state => {
      return state.pagination;
    },
  },

  actions: {
    async getEpisode({ commit }, id) {
      try {
        const { data, success, message } = await api.getEpisode(id);

        if (success) {
          commit('SET_ACTIVE_EPISODE', data);
        }

        return { success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getEpisodeWithAvailableSlots({ commit }, id) {
      try {
        const { data, success, message } = await api.getEpisodeWithAvailableSlots(id);
        if (success) {
          await commit('SET_ACTIVE_EPISODE', data);
          return { success };
        } else {
          return { success: false, message };
        }
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getAdvertisableEpisodes({ commit }, payload) {
      const params = new URLSearchParams();

      if (payload.page) {
        params.append('page', payload.page);
      }

      if (payload.filters) {
        for (const filter in payload.filters) {
          params.append(`filter[${filter}]`, payload.filters[filter]);
        }
      }

      const query = { params };

      try {
        const { success, data, message, meta } = await api.getAdvertisableEpisodes(query);

        if (success) {
          commit('SET_ADVERTISABLE_EPISODES', data);
          commit('SET_PAGINATION', meta.pagination);
        }

        return { success, message, meta };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getLastSeasonForAvailableSlots() {
      try {
        const { data, success } = await api.getLastSeasonForAvailableSlots();
        if (!success) {
          return 0;
        } else {
          return data.last_season_number;
        }
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getBiggestSlotCountForAvailableSlots() {
      try {
        const { data, success } = await api.getBiggestSlotCountForAvailableSlots();
        if (!success) {
          return 0;
        } else {
          return data.biggest_slot_count;
        }
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getSlot({ commit }, slotId) {
      try {
        const { data, success, message } = await api.getSlot(slotId);

        if (success) {
          commit('SET_SELECTED_SLOT', data);
        }

        return { success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async update({ commit }, { episodeId, formData }) {
      try {
        if (formData.clean_title === '') {
          delete formData.clean_title;
        }

        const { success, message, data } = await api.update(episodeId, formData);
        if (success) {
          commit('SET_ACTIVE_EPISODE', data);
        }
        return { success, message, data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async deleteEpisode(_, episodeId) {
      try {
        const { success, message } = await api.deleteEpisode(episodeId);
        return { success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },
  },

  mutations: {
    SET_ADVERTISABLE_EPISODES(state, payload) {
      state.advertisableEpisodes = payload;
    },
    SET_ACTIVE_EPISODE(state, payload) {
      if (payload.slots) {
        payload.slots.sort((a, b) => a.time_position - b.time_position);
      }
      state.activeEpisode = payload;
    },
    SET_SELECTED_SLOT(state, slot) {
      state.selectedSlot = slot;
    },
    PUSH_SLOT(state, slot) {
      state.activeEpisode.slots.push(slot);
    },
    SET_SLOT(state, { slot, index }) {
      state.activeEpisode.slots.splice(index, 1, slot);
    },
    UPDATE_SLOT_POSITION(state, { time_position, index }) {
      state.activeEpisode.slots[index].time_position = time_position;
    },
    DELETE_SLOT(state, slotIndex) {
      state.activeEpisode.slots.splice(slotIndex, 1);
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination;
    },
  },
};

function errorResponse(error, message) {
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default user;
