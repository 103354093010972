import API from '@/api';

async function getPodcasts() {
  const response = await API.get('/reports/podcasts');
  return response.data;
}

async function generateReport(params) {
  const response = await API.get('/reports/generate-report', {
    params,
    responseType: 'blob',
  });

  return response.data;
}

export default {
  getPodcasts,
  generateReport,
};
