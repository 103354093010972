const routes = [
  {
    path: '/orders/:orderId',
    name: 'EditOrder',
    component: () => import(/* webpackChunkName: "EditOrder" */ '../views/EditOrder.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
