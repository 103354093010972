<template>
  <div id="app" class="overflow-x-hidden md:overflow-x-auto">
    <div
      v-if="
        user &&
        currentRoute !== 'ShowPage' &&
        currentRoute !== 'TwoFactorAuthentication' &&
        currentRoute !== 'NeedToVerifyEmail'
      "
    >
      <div class="block md:hidden">
        <MobileHeader v-if="user" @toggleSidebar="toggleSidebar" />
      </div>
      <div class="flex min-h-screen bg-gray-light">
        <!--Sidebar-->
        <Sidebar :is-folded="sidebarIsFolded" @foldSidebar="foldSidebar" />
        <AccountMenu v-if="showAccountMenu" class="ml-sidebar" />
        <Notifications v-if="showNotifications" class="ml-sidebar" />
        <main
          class="main flex flex-col w-full relative"
          :class="{
            'ml-sidebar': user,
            'ml-sidebar-and-subsidebar': accountTabActive,
            'with-topbar': !topBarHidden,
          }"
        >
          <AccountNeedsUpgradeModal />
          <OverdueInvoicesModal />
          <TermsAndConditionsModal />
          <!--Topbar-->
          <TopBar v-if="!topBarHidden" :current-route="currentRoute" />
          <!--Main content-->
          <div
            class="bg-gray-light"
            :class="{
              'px-3 md:px-10 pb-10': user && currentRoute !== 'Analytics',
              'lg:pt-28': user && !topBarHidden,
              'pt-0 lg:pt-10': currentRoute === 'Account',
            }"
          >
            <router-view />
          </div>
          <div v-if="notificationsIsActive" class="main-backdrop" @click="closeNotifications"></div>
          <div class="md:hidden">
            <div v-if="user && !sidebarIsFolded" class="main-backdrop" @click="foldSidebar"></div>
          </div>
        </main>
      </div>
      <portal-target name="modal" multiple />
    </div>
    <div v-else><router-view /></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

const Sidebar = () => import('@/components/shared/layout/sidebar/Sidebar');
const AccountMenu = () => import('@/components/shared/layout/sidebar/AccountMenu');
const Notifications = () => import('@/components/shared/layout/sidebar/notifications/Notifications');
const TopBar = () => import('@/components/shared/layout/TopBar');
const MobileHeader = () => import('@/components/shared/MobileHeader.vue');
const AccountNeedsUpgradeModal = () => import('@/components/shared/modals/AccountNeedsUpgradeModal');
const OverdueInvoicesModal = () => import('@/components/shared/modals/OverdueInvoicesModal');
const TermsAndConditionsModal = () => import('@/components/shared/modals/TermsAndConditionsModal');

export default {
  name: 'App',
  components: {
    TopBar,
    Sidebar,
    AccountMenu,
    Notifications,
    MobileHeader,
    AccountNeedsUpgradeModal,
    OverdueInvoicesModal,
    TermsAndConditionsModal,
  },
  data() {
    return {
      sidebarIsFolded: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      accountTabActive: 'accountTabActive',
      notificationsIsActive: 'notificationsIsActive',
    }),
    currentRoute() {
      return this.$route.name;
    },
    topBarHidden() {
      return (
        !this.user ||
        this.accountTabActive ||
        this.currentRoute === 'Podcasts' ||
        this.currentRoute === 'TwoFactorAuthentication' ||
        this.currentRoute === 'NeedToVerifyEmail'
      );
    },

    showAccountMenu() {
      return this.user && this.accountTabActive;
    },
    showNotifications() {
      return this.user && this.notificationsIsActive;
    },
  },

  methods: {
    ...mapMutations({
      setNotificationsIsActive: 'SET_NOTIFICATIONS_ACTIVE',
    }),
    toggleSidebar() {
      this.sidebarIsFolded = !this.sidebarIsFolded;
    },
    foldSidebar() {
      this.sidebarIsFolded = true;
    },
    closeNotifications() {
      this.setNotificationsIsActive(false);
    },
  },
};
</script>

<style lang="scss" scoped>
$sideBarWidth: 220px;
$subSideBarWidth: 240px;

.main {
  margin-top: calc(var(--mobile-header-height));
}

.main-backdrop {
  @apply fixed top-0 left-0 w-full h-screen z-20;
  background: rgba(#000, 0.5);
}

@media (min-width: 768px) {
  .main {
    margin-top: 0;

    &.with-topbar {
      margin-top: 0;
    }
  }

  .ml-sidebar {
    margin-left: $sideBarWidth;
  }

  .ml-sidebar-and-subsidebar {
    margin-left: $sideBarWidth + $subSideBarWidth;
  }
}
</style>
