import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/assets/css/app.css';
import '@/assets/css/toast.scss';
import VTooltip from 'v-tooltip';
import PortalVue from 'portal-vue';
import VueToastify from 'vue-toastify';
import { formatNumber } from '@/utils/helpers';

Vue.use(PortalVue);

Vue.use(VTooltip);

Vue.use(VueToastify, {
  customNotifications: {
    tSuccess: {
      hideProgressbar: true,
      defaultTitle: false,
      theme: 'success',
      body: 'Changes successfully saved.',
      icon: '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"clip-rule="evenodd" d="M13 22.75V22.75C7.61475 22.75 3.25 18.3853 3.25 13V13C3.25 7.61475 7.61475 3.25 13 3.25V3.25C18.3853 3.25 22.75 7.61475 22.75 13V13C22.75 18.3853 18.3853 22.75 13 22.75Z" stroke="#30750E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M17.3334 10.8334L11.9167 16.25L8.66675 13" stroke="#30750E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
    tError: {
      hideProgressbar: true,
      defaultTitle: false,
      theme: 'error',
      body: 'An error occurred. Please try again later.',
      icon: '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9999 14.2133V10.1616" stroke="white" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round" /> <path d="M12.9988 17.4687C12.8493 17.4687 12.728 17.59 12.7291 17.7395C12.7291 17.889 12.8504 18.0104 12.9999 18.0104C13.1494 18.0104 13.2708 17.889 13.2708 17.7395C13.2708 17.59 13.1494 17.4687 12.9988 17.4687" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M15.1982 4.48396L23.4954 19.005C24.4596 20.6928 23.2408 22.7934 21.2973 22.7934H4.70282C2.75824 22.7934 1.53948 20.6928 2.50474 19.005L10.802 4.48396C11.7737 2.78204 14.2264 2.78204 15.1982 4.48396Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>',
    },
  },
});

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import './plugins/validate.js';

Vue.config.productionTip = false;

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.filter('truncate', function (text, length, clamp = '...') {
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('formatNumber', function (value, currency = false) {
  if (isNaN(value)) return '';
  return formatNumber(value, currency);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
