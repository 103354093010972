const routes = [
  {
    path: '/episodes/:episodeId',
    name: 'Episode',
    component: () => import(/* webpackChunkName: "episode" */ '../views/episode/Episode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/episodes/:episodeId/slots',
    name: 'EpisodeSlots',
    component: () => import(/* webpackChunkName: "episodeSlots" */ '../views/episode/EpisodeSlots.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/podcast/:podcastCode/episodes/create',
    name: 'CreateOrUpdateEpisode',
    component: () =>
      import(/* webpackChunkName: "createOrUpdateEpisode" */ '../views/episode/CreateOrUpdateEpisode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
