const ordersFilters = {
  namespaced: true,

  state: {
    filters: {},
    sort: null,
  },

  getters: {
    filters: state => state.filters,
    sort: state => state.sort,
  },

  mutations: {
    SET_TITLE(state, title) {
      if (title) {
        state.filters.title = title;
      } else {
        delete state.filters.title;
      }
    },

    SET_STATUS(state, status) {
      state.filters.status = status;
    },

    REMOVE_STATUS(state) {
      delete state.filters.status;
    },

    SET_START_DATE(state, startDate) {
      state.filters.start_date = startDate;
    },

    REMOVE_START_DATE(state) {
      delete state.filters.start_date;
    },

    SET_END_DATE(state, endDate) {
      state.filters.end_date = endDate;
    },

    REMOVE_END_DATE(state) {
      delete state.filters.start_date;
    },

    SET_SORT(state, sort) {
      state.sort = sort;
    },
    REMOVE_SORT(state) {
      state.sort = null;
    },

    CLEAR_FILTERS(state) {
      state.filters = {};
      state.sort = null;
    },
  },
};

export default ordersFilters;
