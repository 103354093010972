<template>
  <div class="min-h-screen flex flex-col items-center justify-center">
    <Logo class="w-36 mb-5" />
    <h1 class="text-4xl font-bold mb-2">We are experiencing technical difficulties.</h1>
    <h2 class="text-2xl mb-1">We will keep you informed, sorry for the inconvenience</h2>
  </div>
</template>

<script>
import Logo from '@/components/shared/icons/Logo';
export default {
  name: 'MaintenanceMode',
  components: { Logo },
};
</script>
