import API from '@/api';

async function addEpisode(podcastCode, formData) {
  const { data } = await API.post(`/podcasts/${podcastCode}/episodes`, formData);
  return data;
}

async function getPodcast(podcastCode) {
  const { data } = await API.get(`/podcasts/${podcastCode}`);

  return data;
}

async function showPodcast(podcastCode) {
  const { data } = await API.get(`/show/${podcastCode}`);

  return data;
}

async function store(podcastFormData) {
  const { data } = await API.post('/podcasts', podcastFormData);

  return data;
}

async function update(podcastCode, podcastFormData) {
  const { data } = await API.put(`/podcasts/${podcastCode}`, podcastFormData);

  return data;
}

async function deletePodcast(podcastCode) {
  const { data } = await API.delete(`/podcasts/${podcastCode}`);

  return data;
}

async function getPodcasts() {
  const { data } = await API.get('/podcasts');

  return data;
}

async function getEpisodes(podcastCode, params) {
  const { data } = await API.get(`/podcasts/${podcastCode}/episodes`, params);

  return data;
}

async function showEpisodes(podcastCode, params) {
  const { data } = await API.get(`/show/${podcastCode}/episodes`, params);

  return data;
}

async function importPodcastFromRss(payload) {
  const { data } = await API.post(`/rss`, payload);

  return data;
}

async function importEpisodesFromYoutubeChannel(payload) {
  const { data } = await API.post(`/youtube`, payload);

  return data;
}

async function getLastEpisodeNumber(podcastCode) {
  const { data } = await API.get(`/podcasts/${podcastCode}/episodes/last-number`);

  return data;
}

async function getImportingVideosCount(batchId) {
  const { data } = await API.get(`/youtube/importing-count`, { params: { batchId } });

  return data;
}

async function getLastSeasonNumber(podcastCode) {
  const { data } = await API.get(`/podcasts/${podcastCode}/seasons/last-number`);

  return data;
}

async function getBiggestSlotCount(podcastCode) {
  const { data } = await API.get(`/podcasts/${podcastCode}/seasons/biggest-slot-count`);

  return data;
}

async function batchUpdateSlots(podcastCode, payload) {
  const { data } = await API.put(`/podcasts/${podcastCode}/slots`, payload);

  return data;
}

async function updateDefaultSlots(podcastCode, slots) {
  const { data } = await API.put(`/podcasts/${podcastCode}/default-slots`, {
    default_slots: slots,
  });

  return data;
}

export default {
  addEpisode,
  getPodcast,
  showPodcast,
  showEpisodes,
  getPodcasts,
  getEpisodes,
  store,
  update,
  importPodcastFromRss,
  getLastEpisodeNumber,
  getLastSeasonNumber,
  getBiggestSlotCount,
  batchUpdateSlots,
  updateDefaultSlots,
  deletePodcast,
  importEpisodesFromYoutubeChannel,
  getImportingVideosCount,
};
