import { extend } from 'vee-validate';
import { required, required_if, email, confirmed, numeric, max_value } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} is required',
});
extend('required_if', required_if);
extend('email', email);
extend('integer', {
  ...numeric,
  message: '{_field_} should be an integer',
});
extend('numeric', {
  ...numeric,
  message: '{_field_} should be numeric',
});
extend('confirmed', {
  ...confirmed,
  message: 'Password and Password confirmation fields do not match',
});
extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: '{_field_} field must have at least {length} characters',
});
extend('max', {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ['length'],
  message: '{_field_} field must have no more than {length} characters',
});
extend('min_value', {
  validate(value, { min }) {
    let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
    return newValue >= min;
  },
  params: ['min'],
  message: '{_field_} must be at least {min}',
});
extend('max_value', {
  ...max_value,
  params: ['max'],
  message: '{_field_} must be smaller than {max}',
});
extend('url', {
  validate: str => {
    var pattern = new RegExp(/^(http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  },
  message: 'This is not a valid URL',
});
