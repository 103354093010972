import api from '@/api/campaigns';

const campaigns = {
  namespaced: true,

  state: {
    current: null,
    campaigns: [],
    pagination: {},
    campaignAdvertisers: null,
  },

  getters: {
    current: state => state.current,
    campaignAdvertisers: state => state.campaignAdvertisers,
    campaigns: state => state.campaigns,
    pagination: state => state.pagination,
  },

  actions: {
    async getCampaigns({ commit }, { page, sortBy, filter }) {
      try {
        const { data, meta, success, message } = await api.getCampaigns(page, sortBy, filter);
        if (success) {
          commit('SET_CAMPAIGNS', data);
          commit('SET_PAGINATION', meta.pagination);
        }
        return { data, success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getCampaign({ commit }, campaignId) {
      try {
        const { data, success } = await api.getCampaign(campaignId);
        commit('orders/SET_ORDERS', data.orders, { root: true });
        delete data.orders;
        await commit('SET_CURRENT', data);
        return { success };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async createCampaign(_, payload) {
      try {
        const { success, message, data } = await api.createCampaign(payload);
        return { success, message, data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getCampaignAdvertisers({ commit }) {
      try {
        const { data, success, message } = await api.getCampaignAdvertisers();
        if (success) {
          commit('SET_CAMPAIGN_ADVERTISERS', data);
        }
        return { success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async updateCampaign(_, { form, campaignId }) {
      try {
        const data = await api.update(campaignId, {
          name: form.name,
          advertiser: form.advertiser,
          budget: form.budget,
        });
        if (data.success) return { success: true };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async deleteCampaign(_, campaignId) {
      try {
        const { success, message } = await api.deleteCampaign(campaignId);
        return { success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getOrders({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.page) {
        params.append('page', payload.page);
      }

      if (payload.sortBy) {
        params.append(`sort`, payload.sortBy);
      }

      if (payload.filters) {
        for (const filter in payload.filters) {
          params.append(`filter[${filter}]`, payload.filters[filter]);
        }
      }

      const query = { params };

      const { data, meta } = await api.getOrders(payload.campaignId, query);
      commit('orders/SET_ORDERS', data, { root: true });
      commit('orders/SET_PAGINATION', meta.pagination, { root: true });
      return { success: true, orders: data };
    },

    async getLastOrderProcessingsDate() {
      try {
        const { data, success } = await api.getLastOrderProcessingsDate();
        return { unixTimestamp: data, success };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },
  },

  mutations: {
    SET_CAMPAIGNS: (state, payload) => {
      state.campaigns = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CAMPAIGN_ADVERTISERS: (state, payload) => {
      state.campaignAdvertisers = payload;
    },
    SET_CURRENT: (state, payload) => {
      state.current = payload;
    },
  },
};

function errorResponse(error, message) {
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default campaigns;
