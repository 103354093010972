import api from '@/api';

const payments = {
  namespaced: true,

  state: {
    currentPlan: null,
  },

  getters: {
    currentPlan: state => state.currentPlan,
  },

  actions: {
    async fetchCurrentPlan({ commit }) {
      const response = await api.get('/subscriptions');
      const { success, data } = response.data;
      if (success) {
        commit('setCurrentPlan', data);
      }
      return { success };
    },
  },

  mutations: {
    setCurrentPlan(state, data) {
      state.currentPlan = Array.isArray(data) && data.length == 0 ? null : data;
    },
  },
};

export default payments;
