import api from '@/api/users';

const user = {
  namespaced: true,

  state: {
    user: null,
    organizationMembers: [],
    notifications: [],
    podcastOwner: {},
    stripeClientSecret: '',
    creditCardInfo: null,
    bankOrDebitCardInfo: null,
    notificationCount: 0,
  },

  getters: {
    user: state => {
      return state.user;
    },
    notifications: state => {
      return state.notifications;
    },
    notificationCount: state => {
      return state.notificationCount;
    },
    authenticated: () => {
      return localStorage.getItem('authenticated');
    },
  },

  actions: {
    async signIn({ commit }, { email, password, device_name }) {
      try {
        await api.getCrossReferenceCookie();
        const { data } = await api.login({
          email,
          password,
          device_name,
        });
        if (data.success) {
          await commit('SET_USER', data.data.user);
          return { success: true };
        } else {
          return { success: false, message: data.message };
        }
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getUser({ commit }) {
      try {
        const { data } = await api.me();
        if (data.success) {
          await commit('SET_USER', data.user);
          return { isLoggedIn: true };
        } else {
          await commit('LOGOUT');
          return { isLoggedIn: false };
        }
      } catch (error) {
        await commit('LOGOUT');
        return { isLoggedIn: false };
      }
    },

    async logout({ commit }) {
      try {
        const { data } = await api.logout();
        if (data.success) {
          await commit('LOGOUT');
        }

        return { success: data.success };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async updateUserInformation({ state, commit }, form) {
      const { data } = await api.updateUser(state.user.id, form);
      if (data.success) {
        commit('SET_USER', data.data);
      }
      return { success: data.success, message: data.message };
    },

    async updateUserPassword({ state }, form) {
      try {
        const { data } = await api.updatePassword(state.user.id, form);
        return { success: data.success, message: data.message };
      } catch (error) {
        return { success: false };
      }
    },

    async contactSupport(_, message) {
      try {
        const { data } = await api.contactSupport(message);
        return { success: data.success, message: data.message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },
    async updateRedirectUrl(_, redirect_url) {
      try {
        const { data } = await api.updateRedirectUrl(redirect_url);
        return { success: data.success, message: data.message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getOrganizationMembers({ commit }) {
      try {
        const response = await api.getOrganizationMembers();

        if (response.success) {
          commit('SET_ORGANIZATION_MEMBERS', response.data);
        }

        return response;
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async addOrganizationMember({ commit }, data) {
      const response = await api.addOrganizationMember(data);

      if (response.success) {
        commit('ADD_ORGANIZATION_MEMBER', response.data);
      }

      return response;
    },

    async updateOrganizationMember({ commit }, { memberId, form }) {
      const response = await api.updateOrganizationMember(memberId, form);

      if (response.success) {
        commit('UPDATE_ORGANIZATION_MEMBER', response.data);
      }

      return response;
    },

    async getNotifications({ commit }) {
      const { success, data } = await api.getNotifications();

      if (success) {
        commit('SET_NOTIFICATIONS', data);
      }

      return success;
    },

    async unreadNotificationsCount({ commit }) {
      const { success, data } = await api.unreadNotificationsCount();

      if (success) {
        commit('SET_NOTIFICATION_COUNT', data);
      }

      return success;
    },

    async clearNotifications({ commit }) {
      const { success, message } = await api.clearNotifications();

      if (success) {
        commit('SET_NOTIFICATIONS', []);
      }

      return { success, message };
    },

    async forgotPassword(_, emailForm) {
      await api.getCrossReferenceCookie();
      const response = await api.forgotPassword(emailForm);

      return response;
    },

    async resetPassword(_, form) {
      await api.getCrossReferenceCookie();
      const response = await api.resetPassword(form);

      return response;
    },

    impersonate({ commit }, payload) {
      return api.getCrossReferenceCookie().then(() => {
        return api
          .impersonate(payload)
          .then(({ data }) => {
            if (data.success) {
              commit('SET_USER', data.user);
            }
          })
          .catch(error => {
            return errorResponse(error, "There's been an error, please try again later");
          });
      });
    },
  },

  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
      localStorage.setItem('authenticated', true);
      localStorage.setItem('studeo_userable_type', state.user.userable_type);
    },

    LOGOUT(state) {
      state.user = null;
      localStorage.removeItem('authenticated');
      localStorage.removeItem('studeo_userable_type');
    },

    SET_ORGANIZATION_MEMBERS(state, members) {
      state.organizationMembers = members;
    },

    ADD_ORGANIZATION_MEMBER(state, member) {
      state.organizationMembers = [...state.organizationMembers, member];
    },

    UPDATE_ORGANIZATION_MEMBER(state, member) {
      const index = state.organizationMembers.findIndex(m => m.id === member.id);
      state.organizationMembers[index] = member;
    },

    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },

    SET_NOTIFICATION_COUNT(state, payload) {
      state.notificationCount = payload;
    },

    SET_PODCAST_OWNER(state, payload) {
      state.podcastOwner = payload;
    },

    SET_STRIPE_CLIENT_SECRET(state, secret) {
      state.stripeClientSecret = secret;
    },

    SET_CREDIT_CARD_INFO(state, card) {
      state.creditCardInfo = card;
    },

    SET_BANK_OR_DEBIT_CARD_INFO(state, info) {
      state.bankOrDebitCardInfo = info;
    },
  },
};

function errorResponse(error, message) {
  console.error(error);

  return {
    success: false,
    error: error,
    message: message,
  };
}

export default user;
