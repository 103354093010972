import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    accountTabActive: false,
    notificationsIsActive: false,
    openAccountMenu: null,
    breadcrumbs: [],
  },
  getters: {
    notificationsIsActive: state => {
      return state.notificationsIsActive;
    },
    accountTabActive: state => {
      return state.accountTabActive;
    },
    openAccountMenu: state => {
      return state.openAccountMenu;
    },
    breadcrumbs: state => {
      return state.breadcrumbs;
    },
  },
  mutations: {
    SET_OPEN_ACCOUNT_MENU: (state, payload) => {
      state.openAccountMenu = payload;
    },
    SET_ACCOUNT_TAB_ACTIVE: (state, payload) => {
      state.accountTabActive = payload;
    },
    SET_NOTIFICATIONS_ACTIVE: (state, payload) => {
      state.notificationsIsActive = payload;
    },
    SET_BREADCRUMBS: (state, payload) => {
      state.breadcrumbs = payload;
    },
  },
  actions: {},
});
