const episodesFilters = {
  namespaced: true,

  state: {
    filters: {
      status: 'published',
    },
    sort: null,
    page: null,
  },

  getters: {
    filters: state => state.filters,
    sort: state => state.sort,
    page: state => state.page,
  },

  mutations: {
    SET_STATUS(state, status) {
      state.filters.status = status;
    },

    SET_SEASON(state, seasonId) {
      state.filters.season = seasonId;
    },
    REMOVE_SEASON(state) {
      delete state.filters.season;
    },

    SET_DATE(state, date) {
      state.filters.publish_date_time = date;
    },
    REMOVE_DATE(state) {
      delete state.filters.publish_date_time;
    },

    SET_SLOT_COUNT(state, amount) {
      state.filters.slot_count = amount;
    },
    REMOVE_SLOT_COUNT(state) {
      delete state.filters.slot_count;
    },

    SET_TYPE(state, type) {
      state.filters.type = type;
    },
    REMOVE_TYPE(state) {
      delete state.filters.type;
    },

    SET_TITLE(state, title) {
      state.filters.title = title;
    },

    SET_PAGE(state, page) {
      state.page = page;
    },

    SET_SORT(state, sort) {
      state.sort = sort;
    },
    REMOVE_SORT(state) {
      state.sort = null;
    },

    CLEAR_FILTERS(state) {
      state.filters = { status: 'published' };
      state.sort = null;
      state.page = null;
    },
  },
};

export default episodesFilters;
