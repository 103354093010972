import Podcasts from '@/views/Podcasts';

const userType = () => localStorage.getItem('studeo_userable_type');

const redirectIfAdManager = (from, to, next) =>
  userType() === 'ad_manager' ? next({ name: 'Campaigns' }) : next();

const routes = [
  {
    path: '/',
    name: 'Podcasts',
    component: Podcasts,
    beforeEnter: redirectIfAdManager,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/podcasts/create',
    name: 'CreatePodcast',
    component: () => import(/* webpackChunkName: "createPodcast" */ '../views/podcast/CreatePodcast.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/podcasts/:podcastCode',
    name: 'Podcast',
    component: () => import(/* webpackChunkName: "podcast" */ '../views/podcast/Podcast.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/podcasts/:podcastCode/update',
    name: 'UpdatePodcast',
    component: () => import(/* webpackChunkName: "updatePodcast" */ '../views/podcast/UpdatePodcast.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/channels/:podcastCode',
    name: 'ShowPage',
    component: () => import(/* webpackChunkName: "ShowPage" */ '../views/podcast/ShowPage.vue'),
    props: true,
  },
];

export default routes;
