const routes = [
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "signin" */ '../views/auth/SignIn.vue'),
  },
  {
    path: '/request-access',
    name: 'RequestAccess',
    component: () => import(/* webpackChunkName: "requestAccess" */ '../views/RequestAccess.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/auth/ResetPassword.vue'),
  },
  {
    path: '/2fa',
    name: 'TwoFactorAuthentication',
    component: () =>
      import(/* webpackChunkName: "twoFactorAuth" */ '../views/auth/TwoFactorAuthentication.vue'),
  },
];

export default routes;
