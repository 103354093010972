import API from '@/api';

async function getEpisode(id) {
  const response = await API.get(`/episodes/${id}`);
  return response.data;
}

async function getEpisodeWithAvailableSlots(id) {
  const response = await API.get(`/episodes/${id}/with-available-slots`);
  return response.data;
}

async function getAdvertisableEpisodes(params) {
  const response = await API.get(`/episodes/with-available-slots`, params);

  return response.data;
}

async function getLastSeasonForAvailableSlots() {
  const response = await API.get(`/episodes/last-season-for-available-slots`);

  return response.data;
}

async function getBiggestSlotCountForAvailableSlots() {
  const response = await API.get(`/episodes/biggest-slot-count-for-available-slots`);

  return response.data;
}

async function getSlot(id) {
  const { data } = await API.get(`/slots/${id}`);

  return data;
}

async function update(episodeId, data) {
  const response = await API.put(`/episodes/${episodeId}`, data);
  return response.data;
}

async function deleteEpisode(episodeId) {
  const response = await API.delete(`/episodes/${episodeId}`);
  return response.data;
}

export default {
  getEpisode,
  getEpisodeWithAvailableSlots,
  getAdvertisableEpisodes,
  getLastSeasonForAvailableSlots,
  getBiggestSlotCountForAvailableSlots,
  getSlot,
  update,
  deleteEpisode,
};
