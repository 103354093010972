import API from '@/api';

async function me() {
  return await API.get(`/users/me`);
}

async function getCrossReferenceCookie() {
  await API.get(`/sanctum/csrf-cookie`);
}

async function login({ email, password, device_name }) {
  return await await API.post('/login', {
    email,
    password,
    device_name,
  });
}

async function logout() {
  return await await API.post('/logout');
}

async function updateUser(userId, data) {
  return await API.put(`/users/${userId}`, {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
  });
}

async function updatePassword(userId, data) {
  return await API.put(`/users/${userId}/password`, {
    current_password: data.current_password,
    new_password: data.new_password,
    new_password_confirmation: data.new_password_confirmation,
  });
}

async function contactSupport(message) {
  return await API.post('/users/support', { message });
}
async function updateRedirectUrl(redirect_url) {
  return await API.post('/users/redirect-url', { redirect_url });
}

async function getOrganizationMembers() {
  const { data } = await API.get('/users/organization-members');

  return data;
}

async function addOrganizationMember(payload) {
  const { data } = await API.post('/users/organization-members', payload);

  return data;
}

async function updateOrganizationMember(memberId, memberData) {
  const { data } = await API.put(`/users/organization-members/${memberId}`, memberData);

  return data;
}

async function deleteOrganizationMember(memberId) {
  const { data } = await API.delete(`/users/organization-members/${memberId}`);

  return data;
}

async function getNotifications() {
  const { data } = await API.get('/notifications');

  return data;
}

async function unreadNotificationsCount() {
  const { data } = await API.get('/notifications/unread-count');

  return data;
}

async function clearNotifications() {
  const { data } = await API.post('/notifications/clear-all');

  return data;
}

async function forgotPassword(form) {
  const { data } = await API.post('/password/email', form);

  return data;
}

async function resetPassword(form) {
  const { data } = await API.post('/password/reset', form);

  return data;
}

const impersonate = data => {
  return API.post(`/validate-token`, data);
};

export default {
  me,
  login,
  logout,
  getCrossReferenceCookie,
  updateUser,
  updatePassword,
  contactSupport,
  updateRedirectUrl,
  getOrganizationMembers,
  addOrganizationMember,
  updateOrganizationMember,
  deleteOrganizationMember,
  getNotifications,
  unreadNotificationsCount,
  clearNotifications,
  forgotPassword,
  resetPassword,
  impersonate,
};
