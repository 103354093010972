import api from '@/api/orders';

const orders = {
  namespaced: true,

  state: {
    current: null,
    orders: [],
    pagination: {},
  },

  getters: {
    current: state => state.current,
    orders: state => state.orders,
    pagination: state => state.pagination,
  },

  actions: {
    async getOrder({ commit }, orderId) {
      try {
        const { data, success } = await api.getOrder(orderId);
        await commit('SET_CURRENT', data);
        return { success };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async update({ commit }, { form, orderId }) {
      try {
        const { success, message, data } = await api.update(orderId, form);
        if (success) {
          commit('SET_CURRENT', data);
        }
        return { success, message, data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async toggleState({ commit }, orderId) {
      const { success, message, data } = await api.toggleState(orderId);
      if (success) {
        commit('SET_ORDER_STATE', { orderId, data });
      }
      return { success, message };
    },
  },

  mutations: {
    SET_CURRENT: (state, payload) => {
      state.current = payload;
    },

    SET_ORDERS: (state, payload) => {
      state.orders = payload;
    },

    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },

    SET_ORDER_STATE: (state, payload) => {
      state.orders = [
        ...state.orders.map(order => (order.id !== payload.orderId ? order : { ...order, ...payload.data })),
      ];
    },
  },
};

function errorResponse(error, message) {
  console.log(error);
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default orders;
