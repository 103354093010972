import API from '@/api';

async function getOrder(orderId) {
  let endpoint = `/orders/${orderId}`;
  const { data } = await API.get(endpoint);
  return data;
}

async function update(orderId, form) {
  const { data } = await API.put(`/orders/${orderId}`, form);
  return data;
}

async function toggleState(orderId) {
  const { data } = await API.post(`/orders/${orderId}/toggle-state`);
  return data;
}

async function addAdToOrder(orderId, form) {
  const { data } = await API.post(`/orders/${orderId}/advertisements`, form);
  return data;
}

async function deleteOrder(orderId) {
  const response = await API.delete(`/orders/${orderId}`);
  return response.data;
}

async function duplicateOrder(orderId) {
  const response = await API.post(`/orders/${orderId}/duplicate`);
  return response.data;
}

export default {
  getOrder,
  update,
  addAdToOrder,
  deleteOrder,
  toggleState,
  duplicateOrder,
};
