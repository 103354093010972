<template>
  <div v-if="user && !loading && podcasts.length">
    <div class="w-full flex lg:flex-nowrap flex-wrap items-center justify-between mb-10 pt-10">
      <h2 class="heading-m-700 mb-4 lg:mb-0">Hi {{ user.first_name }} 👋 here are your podcasts</h2>
      <div
        v-if="['admin', 'producer'].includes(user.role)"
        class="flex items-center justify-between space-x-4"
      >
        <BaseButton text="Create New Podcast" @onClick="$router.push({ name: 'CreatePodcast' })" />
        <ImportRssFeed with-button />
        <!-- <ImportPodcast /> -->
      </div>
    </div>
    <div class="grid grid-cols-1 xl:grid-cols-2 gap-7">
      <Podcast v-for="podcast in podcasts" :key="podcast.code" :podcast="podcast" />
    </div>
  </div>
  <PodcastsEmptyState v-else-if="!loading" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// const ImportPodcast = () => import('@/components/shared/modals/ImportPodcast');
const ImportRssFeed = () => import('@/components/shared/modals/ImportRssFeed');
const Podcast = () => import('@/components/podcasts/Podcast');
const PodcastsEmptyState = () => import('@/components/podcasts/PodcastsEmptyState');
const BaseButton = () => import('@/components/shared/buttons/BaseButton');
export default {
  name: 'Podcasts',
  components: { BaseButton, Podcast, PodcastsEmptyState, ImportRssFeed },
  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapGetters({
      podcasts: 'podcasts/podcasts',
      user: 'user/user',
    }),
  },

  async mounted() {
    try {
      await this.getPodcasts();
    } catch (error) {
      this.$vToastify.tError(error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      getPodcasts: 'podcasts/getPodcasts',
    }),
  },
};
</script>
