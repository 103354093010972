import API from '@/api';

async function createAdRequest(payload) {
  const { data } = await API.post(`/studeoads`, payload);

  return data;
}

async function getAdvertisements(status) {
  const { data } = await API.get(`/studeoads`, { params: { status } });

  return data;
}

async function uploadAudioForScript(id, payload) {
  const { data } = await API.post(`/studeoads/${id}/upload-audio-for-script`, payload);

  return data;
}

async function requestChanges(id, payload) {
  const { data } = await API.put(`/studeoads/${id}/request-changes`, payload);

  return data;
}

async function rejectAdvertisement(id) {
  const { data } = await API.put(`/studeoads/${id}/reject`);

  return data;
}

async function approveAdvertisement(id) {
  const { data } = await API.put(`/studeoads/${id}/approve`);

  return data;
}

async function retrieveAdvertisement(payload) {
  const { data } = await API.get(`/studeoads/${payload}`);

  return data;
}

async function getActivity(adId) {
  const { data } = await API.get(`/studeoads/${adId}/activity`);

  return data;
}

async function sendMessage(adId, message) {
  const { data } = await API.post(`/studeoads/${adId}/message`, { message });

  return data;
}

async function editAdRequest(request) {
  const { data } = await API.put('/studeoads', request);

  return data;
}

async function deleteAdvertisement(adId) {
  const { data } = await API.delete(`/studeoads/${adId}`);

  return data;
}

export default {
  createAdRequest,
  getAdvertisements,
  uploadAudioForScript,
  rejectAdvertisement,
  approveAdvertisement,
  retrieveAdvertisement,
  sendMessage,
  getActivity,
  editAdRequest,
  deleteAdvertisement,
  requestChanges,
};
