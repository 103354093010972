import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import authRoutes from './auth';
import podcastsRoutes from './podcasts';
import episodesRoutes from './episodes';
import advertisementsRoutes from './advertisements';
import campaignsRoutes from './campaigns';
import settingsRoutes from './settings';
import ordersRoutes from './orders';

import MaintenanceMode from '@/views/MaintenanceMode';

Vue.use(VueRouter);

const routes = [
  {
    path: '/impersonate',
    meta: {
      isImpersonate: true,
    },
  },

  { path: '/mantainance', component: MaintenanceMode },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/team-members',
    name: 'TeamMembers',
    component: () =>
      import(
        /* webpackChunkName: "organizationMembers" */ '../views/organizationMembers/OrganizationMembers.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  ...authRoutes,
  ...podcastsRoutes,
  ...episodesRoutes,
  ...advertisementsRoutes,
  ...campaignsRoutes,
  ...settingsRoutes,
  ...ordersRoutes,
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/errors/Forbidden.vue'),
  },
  {
    path: '/verify-email',
    name: 'NeedToVerifyEmail',
    component: () => import(/* webpackChunkName: "verifyEmail" */ '../views/errors/NeedToVerifyEmail.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/errors/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isImpersonate = to.matched.some(record => record.meta.isImpersonate);

  if (isImpersonate) {
    store.dispatch('user/impersonate', to.query).finally(() => {
      router.push('/');
      window.location.reload();
    });
  }
  const authUser = store.getters['user/user'];
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);

  if (reqAuth && !authUser) {
    store.dispatch('user/getUser').then(() => {
      store.dispatch('user/unreadNotificationsCount');
      let user = store.getters['user/user'];
      if (!user) {
        next({ name: 'SignIn' });
      } else {
        if (!logoutAdmin(next, user)) next();
      }
    });
  } else {
    if (reqAuth) store.dispatch('user/unreadNotificationsCount');
    if (!logoutAdmin(next, authUser)) next();
  }
});

function logoutAdmin(next, user = null) {
  let shouldLogout = false;
  if (user && user.userable_type == 'admin') {
    store.dispatch('user/logout').then(() => next({ name: 'SignIn' }));
    shouldLogout = true;
  }
  return shouldLogout;
}

export default router;
