import API from '@/api';

async function uploadFile(data) {
  const response = await API.post(`/uploads`, data);

  return response.data;
}

async function deleteFile(data) {
  const response = await API.delete(`/uploads`, { params: data });

  return response.data;
}

export default {
  uploadFile,
  deleteFile,
};
