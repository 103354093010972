var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-x-hidden md:overflow-x-auto",attrs:{"id":"app"}},[(
      _vm.user &&
      _vm.currentRoute !== 'ShowPage' &&
      _vm.currentRoute !== 'TwoFactorAuthentication' &&
      _vm.currentRoute !== 'NeedToVerifyEmail'
    )?_c('div',[_c('div',{staticClass:"block md:hidden"},[(_vm.user)?_c('MobileHeader',{on:{"toggleSidebar":_vm.toggleSidebar}}):_vm._e()],1),_c('div',{staticClass:"flex min-h-screen bg-gray-light"},[_c('Sidebar',{attrs:{"is-folded":_vm.sidebarIsFolded},on:{"foldSidebar":_vm.foldSidebar}}),(_vm.showAccountMenu)?_c('AccountMenu',{staticClass:"ml-sidebar"}):_vm._e(),(_vm.showNotifications)?_c('Notifications',{staticClass:"ml-sidebar"}):_vm._e(),_c('main',{staticClass:"main flex flex-col w-full relative",class:{
          'ml-sidebar': _vm.user,
          'ml-sidebar-and-subsidebar': _vm.accountTabActive,
          'with-topbar': !_vm.topBarHidden,
        }},[_c('AccountNeedsUpgradeModal'),_c('OverdueInvoicesModal'),_c('TermsAndConditionsModal'),(!_vm.topBarHidden)?_c('TopBar',{attrs:{"current-route":_vm.currentRoute}}):_vm._e(),_c('div',{staticClass:"bg-gray-light",class:{
            'px-3 md:px-10 pb-10': _vm.user && _vm.currentRoute !== 'Analytics',
            'lg:pt-28': _vm.user && !_vm.topBarHidden,
            'pt-0 lg:pt-10': _vm.currentRoute === 'Account',
          }},[_c('router-view')],1),(_vm.notificationsIsActive)?_c('div',{staticClass:"main-backdrop",on:{"click":_vm.closeNotifications}}):_vm._e(),_c('div',{staticClass:"md:hidden"},[(_vm.user && !_vm.sidebarIsFolded)?_c('div',{staticClass:"main-backdrop",on:{"click":_vm.foldSidebar}}):_vm._e()])],1)],1),_c('portal-target',{attrs:{"name":"modal","multiple":""}})],1):_c('div',[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }