import api from '@/api/uploads';

const uploads = {
  namespaced: true,

  state: {},

  actions: {
    async uploadFile(_, payload) {
      const { uploads } = await api.uploadFile(payload);

      return uploads[0];
    },

    async deleteFile(_, payload) {
      const { success } = await api.deleteFile(payload);
      return success;
    },
  },
};

export default uploads;
