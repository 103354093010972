import api from '@/api/campaigns';

const createOrder = {
  namespaced: true,

  state: {
    steps: [
      {
        title: '1. Order Details',
        completed: false,
      },
      {
        title: '2. Ads',
        completed: false,
      },
      {
        title: '3. Targets',
        completed: false,
      },
    ],
    currentStepIndex: 0,
    loadingPotentialTargets: false,
    potentialTargetsError: null,
    //for step 1:
    orderForm: {
      title: '',
      impressions_goal: null,
      impressions_cap: null,
      start_date: null,
      end_date: null,
      notes: '',
      priority: 1,
    },
    //for step 2:
    preRollFiles: [],
    midRollFiles: [],
    postRollFiles: [],
    //for step 3:
    targetablePodcasts: [],
    selectedPodcasts: [],
    selectedEpisodes: [],
    targetableTags: [],
    selectedTags: [],
  },

  getters: {
    steps: state => state.steps,
    currentStepIndex: state => state.currentStepIndex,
    loadingPotentialTargets: state => state.loadingPotentialTargets,
    potentialTargetsError: state => state.potentialTargetsError,
    //step 1:
    orderForm: state => state.orderForm,
    //step 2:
    orderFilesFilled: state =>
      state.preRollFiles.length || state.midRollFiles.length || state.postRollFiles.length,
    //step 3:
    targetableEpisodes: state => state.targetableEpisodes,
    selectedEpisodes: state => state.selectedEpisodes,
    targetablePodcasts: state => state.targetablePodcasts,
    selectedPodcasts: state => state.selectedPodcasts,
    targetableTags: state => state.targetableTags,
    selectedTags: state => state.selectedTags,

    atLeastOneTargetSelected: state =>
      state.selectedPodcasts.length || state.selectedEpisodes.length || state.selectedTags.length,
  },

  actions: {
    async createOrder(_, orderData) {
      try {
        const { data, success, message } = await api.createOrder(orderData);
        return { data, success, message };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getPotentialTargets({ commit }) {
      await commit('SET_LOADING_POTENTIAL_TARGETS', true);
      try {
        const { data } = await api.getPotentialTargets();
        await commit('SET_TARGETABLE_TAGS', data.tags);
        await commit('SET_TARGETABLE_PODCASTS', data.podcasts);
        await commit('SET_POTENTIAL_TARGETS_ERROR', null);
      } catch (error) {
        await commit('SET_POTENTIAL_TARGETS_ERROR', error);
      } finally {
        await commit('SET_LOADING_POTENTIAL_TARGETS', false);
      }
    },

    packOrderData({ state }) {
      const order_files = getOrderFiles(state);
      const targets = getTargets(state);
      return {
        title: state.orderForm.title,
        impressions_goal: state.orderForm.impressions_goal,
        impressions_cap: state.orderForm.impressions_cap,
        priority: parseInt(state.orderForm.priority),
        start_date: state.orderForm.start_date,
        end_date: state.orderForm.end_date,
        notes: state.orderForm.notes,
        order_files,
        targets,
      };
    },
  },

  mutations: {
    SET_CURRENT_STEP_INDEX: (state, payload) => {
      state.currentStepIndex = payload;
    },
    SET_STEP_COMPLETED: (state, payload) => {
      state.steps[payload.index].completed = payload.completed;
    },
    //step 1:
    SET_ORDER_FORM: (state, payload) => {
      state.orderForm = payload;
    },
    //step 2:
    APPEND_FILE: (state, payload) => {
      state[`${payload.spot}Files`].push(payload.file);
    },
    REMOVE_FILE: (state, payload) => {
      const filesArray = state[`${payload.spot}Files`];
      const index = filesArray.findIndex(file => file.url === payload.fileUrl);
      filesArray.splice(index, 1);
    },
    //step 3:
    //podcasts
    SET_TARGETABLE_PODCASTS: (state, payload) => {
      state.targetablePodcasts = payload;
    },
    SET_SELECTED_PODCASTS: (state, payload) => {
      state.selectedPodcasts = payload;
    },
    UNSELECT_PODCAST: (state, podcastCode) => {
      const index = state.selectedPodcasts.findIndex(podcast => podcast.code === podcastCode);
      state.selectedPodcasts.splice(index, 1);
    },
    //episodes
    SET_SELECTED_EPISODES: (state, payload) => {
      state.selectedEpisodes = payload;
    },
    UNSELECT_EPISODE: (state, episodedId) => {
      const index = state.selectedEpisodes.findIndex(episode => episode.id === episodedId);
      state.selectedEpisodes.splice(index, 1);
    },
    SET_LOADING_POTENTIAL_TARGETS: (state, payload) => {
      state.loadingPotentialTargets = payload;
    },
    SET_POTENTIAL_TARGETS_ERROR: (state, payload) => {
      state.potentialTargetsError = payload;
    },
    //tags
    SET_TARGETABLE_TAGS: (state, payload) => {
      state.targetableTags = payload;
    },
    SET_SELECTED_TAGS: (state, payload) => {
      state.selectedTags = payload;
    },
    UNSELECT_TAG: (state, tagId) => {
      const index = state.selectedTags.findIndex(tag => tag.id === tagId);
      state.selectedTags.splice(index, 1);
    },
    RESET_ORDER: state => {
      state.currentStepIndex = 0;
      //for step 1:
      state.orderForm = {
        title: '',
        impressions_goal: null,
        impressions_cap: null,
        start_date: null,
        end_date: null,
        notes: '',
        priority: 1,
      };
      //for step 2:
      state.preRollFiles = [];
      state.midRollFiles = [];
      state.postRollFiles = [];
      //for step 3:
      state.targetablePodcasts = [];
      state.selectedPodcasts = [];
      state.selectedEpisodes = [];
      state.targetableTags = [];
      state.selectedTags = [];

      for (let i = 0; i < 3; i++) {
        state.steps[i].completed = false;
      }
    },
  },
};

function getOrderFiles({ preRollFiles, midRollFiles, postRollFiles }) {
  const files = [];

  loadFiles(preRollFiles, 'pre');
  loadFiles(midRollFiles, 'mid');
  loadFiles(postRollFiles, 'post');

  function loadFiles(iterable, position) {
    iterable.forEach(file => {
      files.push({
        position,
        url: file.url,
        duration_in_seconds: file.durationInSeconds || 0,
      });
    });
  }

  return files;
}

function getTargets({ selectedPodcasts, selectedEpisodes, selectedTags }) {
  const targets = {};

  loadTargets(selectedPodcasts, 'podcasts');
  loadTargets(selectedEpisodes, 'episodes');
  loadTargets(selectedTags, 'tags');

  function loadTargets(iterable, propertyName) {
    targets[propertyName] = iterable.map(target => (propertyName == 'podcasts' ? target.code : target.id));
  }

  return targets;
}

function errorResponse(error, message) {
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default createOrder;
