import api from '@/api/reports';
import moment from 'moment';
import { getErrorMessageFrom } from '@/utils/helpers';

const reports = {
  namespaced: true,

  state: {
    podcasts: null,
  },

  getters: {
    podcasts: state => state.podcasts,
  },

  actions: {
    async getPodcasts({ commit }) {
      try {
        const { data } = await api.getPodcasts();
        await commit('SET_PODCASTS', data);
      } catch (error) {
        return errorResponse(error);
      }
    },

    async generateReport(_, params) {
      try {
        const data = await api.generateReport(params);
        if (data.size) {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([data]));
          const fileName = 'studeo-report_' + moment().format('YYYY-MM-DD') + '.pdf';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          throw new Error('There was an error while generating the report');
        }
      } catch (error) {
        throw new Error('There was an error while generating the report');
      }
    },
  },

  mutations: {
    SET_PODCASTS: (state, payload) => {
      state.podcasts = payload;
    },
  },
};

function errorResponse(error, msg) {
  const success = false;
  const message = msg || getErrorMessageFrom(error) || error;
  return { error, success, message };
}

export default reports;
