import api from '@/api/podcasts';

const user = {
  namespaced: true,

  state: {
    youtubeForm: [],
    podcasts: [],
    activePodcast: null,
    defaultSlotPositions: [
      {
        type: 'pre',
        name: 'Pre Roll',
        limit_of_ads: 5,
        time_position: -1,
        price: 10,
      },
      {
        type: 'mid',
        name: 'Mid Roll',
        limit_of_ads: 5,
        time_position: -1,
        price: 10,
      },
      {
        type: 'post',
        name: 'Post Roll',
        limit_of_ads: 5,
        time_position: -1,
        price: 10,
      },
    ],
  },

  getters: {
    podcasts: state => {
      return state.podcasts;
    },

    activePodcast: state => {
      return state.activePodcast;
    },

    activePodcastEpisodes: state => {
      return state.activePodcastEpisodes;
    },

    defaultSlotPositions: state => {
      return state.defaultSlotPositions;
    },

    youtubeForm: state => {
      return state.youtubeForm;
    },
  },

  actions: {
    async getPodcasts({ commit }) {
      try {
        const { data, success, message } = await api.getPodcasts();
        if (success) {
          await commit('SET_PODCASTS', data);
          return { success };
        } else {
          return { success: false, message };
        }
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    /* TODO: getPodcasts should NOT retreive all the episodes,
    it could be too much data, it should only fetch the
    episodes' count in order to display it */
    async getPodcast({ commit }, podcastCode) {
      try {
        const { data } = await api.getPodcast(podcastCode);
        await commit('SET_ACTIVE_PODCAST', data);
        return { success: true, data: data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async showPodcast(_, podcastCode) {
      try {
        const { data } = await api.showPodcast(podcastCode);
        return { success: true, data: data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async storePodcast(_, form) {
      const language = form.language.value.toLowerCase();
      const categories = form.categories.map(category => category.id);
      const tags = form.tags.map(tag => tag.name.toLowerCase());

      const data = await api.store({
        name: form.name,
        subtitle: form.subtitle,
        summary: form.summary,
        network: form.network,
        type: form.type,
        rating: form.rating,
        authors_name: form.authors_name,
        authors_website: form.authors_website,
        owners_name: form.owners_name,
        owners_email: form.owners_email,
        copyright_notice: form.copyright_notice,
        image_url: form.image_url,
        social_media: form.social_media,
        importing_episodes: form.importing_episodes,
        slug: form.slug,
        categories,
        language,
        tags,
      });
      return { success: data.success, data: data.data };
    },

    async updatePodcast({ commit }, { form, podcastCode }) {
      const language = form.language.value.toLowerCase();
      const categories = form.categories.map(category => category.id);
      const tags = form.tags.map(tag => tag.name.toLowerCase());

      const data = await api.update(podcastCode, {
        name: form.name,
        subtitle: form.subtitle,
        summary: form.summary,
        network: form.network,
        type: form.type,
        rating: form.rating,
        authors_name: form.authors_name,
        authors_website: form.authors_website,
        owners_name: form.owners_name,
        owners_email: form.owners_email,
        copyright_notice: form.copyright_notice,
        image_url: form.image_url,
        social_media: form.social_media,
        slug: form.slug,
        categories,
        language,
        tags,
      });
      await commit('SET_ACTIVE_PODCAST', data.data);
      return { success: data.success, data: data.data };
    },

    async getImportingVideosCount(_, batchId) {
      try {
        const data = await api.getImportingVideosCount(batchId);
        return { success: data.success, data: data.data };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async deletePodcast(_, podcastCode) {
      try {
        const data = await api.deletePodcast(podcastCode);
        if (data.success) return { success: true };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async getEpisodes(_, payload) {
      const params = new URLSearchParams();

      if (payload.page) {
        params.append('page', payload.page);
      }

      if (payload.sortBy) {
        params.append(`sort`, payload.sortBy);
      }

      if (payload.filters) {
        for (const filter in payload.filters) {
          params.append(`filter[${filter}]`, payload.filters[filter]);
        }
      }

      const query = { params };

      try {
        const { data, meta } = await api.getEpisodes(payload.podcastCode, query);
        return { success: true, episodes: data, pagination: meta.pagination };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    async showEpisodes(_, payload) {
      const params = new URLSearchParams();

      params.append('limit', 50);

      if (payload.page) {
        params.append('page', payload.page);
      }

      params.append(`filter[status]`, 'published');

      const query = { params };

      try {
        const { data, meta } = await api.showEpisodes(payload.podcastCode, query);
        return { success: true, episodes: data, pagination: meta.pagination };
      } catch (error) {
        return errorResponse(error, "There's been an error, please try again later");
      }
    },

    /* TODO: This should also take into account the season for better UX */
    async getLastEpisodeNumber(_, podcastCode) {
      try {
        const { data, success } = await api.getLastEpisodeNumber(podcastCode);

        if (!success) {
          return 0;
        }

        return data.last_episode_number;
      } catch (error) {
        console.warn(error);
        return 0;
      }
    },

    async getLastSeasonNumber(_, podcastCode) {
      try {
        const { data, success } = await api.getLastSeasonNumber(podcastCode);

        if (!success) {
          return 0;
        }

        return data.last_season_number;
      } catch (error) {
        console.warn(error);
        return 0;
      }
    },

    async getBiggestSlotCount(_, podcastCode) {
      try {
        const { data, success } = await api.getBiggestSlotCount(podcastCode);

        if (!success) {
          return 0;
        }

        return data.bigest_slot_count;
      } catch (error) {
        console.warn(error);
        return 0;
      }
    },

    async addEpisode(_, payload) {
      return await api.addEpisode(payload.podcastCode, payload);
    },

    async importPodcastFromRss(_, payload) {
      return await api.importPodcastFromRss(payload);
    },

    async importEpisodesFromYoutubeChannel(_, payload) {
      return await api.importEpisodesFromYoutubeChannel(payload);
    },

    async batchUpdateSlots({ state }, payload) {
      return await api.batchUpdateSlots(state.activePodcast.code, payload);
    },

    async updateDefaultSlots({ commit, state }, payload) {
      const response = await api.updateDefaultSlots(state.activePodcast.code, payload);

      if (response.success) {
        commit('SET_ACTIVE_PODCAST', response.data);
      }

      return response;
    },
  },

  mutations: {
    SET_PODCASTS(state, payload) {
      state.podcasts = payload;
    },

    SET_ACTIVE_PODCAST(state, payload) {
      state.activePodcast = payload;
    },

    SET_YOUTUBE_FORM(state, payload) {
      state.youtubeForm = payload;
    },
  },
};

function errorResponse(error, message) {
  return {
    success: false,
    error: error,
    message: message,
  };
}

export default user;
