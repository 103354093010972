const routes = [
  {
    path: '/campaigns/create',
    name: 'CreateCampaign',
    component: () => import(/* webpackChunkName: "CreateCampaign" */ '../views/campaigns/CreateCampaign.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigns/:campaignId/orders/create',
    name: 'CreateOrder',
    component: () =>
      import(/* webpackChunkName: "CreateOrder" */ '../views/campaigns/orders/create/CreateOrder.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigns/:campaignId/orders',
    name: 'CampaignOrders',
    component: () => import(/* webpackChunkName: "CampaignOrders" */ '../views/campaigns/CampaignOrders.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
