import api from '@/api/advertisements';

const advertisements = {
  namespaced: true,

  state: {
    selectedAdvertisement: null,
    advertisements: [],
    selectedSlot: null,
    status: 'pending',
    uploadedAudioError: '',
    activity: null,
  },

  getters: {
    current: state => {
      return state.selectedAdvertisement;
    },
    currentAdvertisements: state => {
      return state.advertisements;
    },
    status: state => {
      return state.status;
    },
    uploadedAudioError: state => {
      return state.uploadedAudioError;
    },
    activity: state => {
      return state.activity;
    },
  },

  actions: {
    async createAdRequest({ commit }, payload) {
      try {
        const { data, success, message } = await api.createAdRequest(payload);
        if (success) {
          await commit('SET_SELECTED_ADVERTISEMENT', data);
          return { success };
        } else {
          return { success: false, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async editAdRequest({ commit }, payload) {
      try {
        const { data, success, message } = await api.editAdRequest(payload);
        if (success) {
          await commit('SET_SELECTED_ADVERTISEMENT', data);
          return { success };
        } else {
          return { success: false, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async getAdvertisements({ commit }) {
      try {
        const { data, success, message } = await api.getAdvertisements();
        if (success) {
          await commit('SET_ADVERTISEMENTS', data);
          return { success };
        } else {
          return { success: false, message };
        }
      } catch (error) {
        return errorResponse(error);
      }
    },

    async uploadAudioForScript(_, payload) {
      try {
        const { success, message } = await api.uploadAudioForScript(payload.id, payload.form);
        return { success, message };
      } catch (error) {
        return errorResponse(error);
      }
    },

    async approveAdvertisement({ commit }, advertisementId) {
      try {
        const { data, success, message } = await api.approveAdvertisement(advertisementId);
        if (success) {
          commit('SET_SELECTED_ADVERTISEMENT', data);
        }
        return { success, message };
      } catch (error) {
        return errorResponse(error);
      }
    },

    async retrieveAdvertisement({ commit }, payload) {
      const response = await api.retrieveAdvertisement(payload);

      if (response.success) {
        commit('SET_SELECTED_ADVERTISEMENT', response.data);
      }

      return response;
    },

    async getActivity({ commit }, adId) {
      try {
        const { success, data } = await api.getActivity(adId);

        if (success) {
          commit('SET_ACTIVITY', data);
        }

        return success;
      } catch (error) {
        return errorResponse(error);
      }
    },

    async sendMessage(_, { adId, message }) {
      const { success } = await api.sendMessage(adId, message);
      return success;
    },

    async deleteAdvertisement(_, adId) {
      return await api.deleteAdvertisement(adId);
    },
  },

  mutations: {
    SET_SELECTED_ADVERTISEMENT(state, advertisement) {
      state.selectedAdvertisement = advertisement;
    },
    SET_ADVERTISEMENTS(state, payload) {
      state.advertisements = payload;
    },
    SET_STATUS(state, payload) {
      state.status = payload;
    },
    SET_UPLOADED_AUDIO_ERROR(state, payload) {
      state.uploadedAudioError = payload;
    },
    SET_ACTIVITY(state, payload) {
      state.activity = payload;
    },
  },
};

function errorResponse(error, message = "There's been an error, please try again later") {
  console.error(error);

  return {
    success: false,
    error: error,
    message: message,
  };
}

export default advertisements;
