export const scrollToError = vm => {
  vm.$nextTick(() => {
    const yOffset = -120;
    const element = document.querySelector('.has-errors');
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y });
    return;
  });
};

export const selectProps = (...props) => {
  return function (obj) {
    const newObj = {};
    props.forEach(name => {
      newObj[name] = obj[name];
    });

    return newObj;
  };
};

export const getErrorMessageFrom = error => {
  const { response } = error;
  if (!response || !response.data) {
    return error.message;
  }

  const { message, errors } = response.data;
  const concatedErrors = concatenateAllErrors(errors);

  return concatedErrors ? concatedErrors : message;
};

// Errors by field is a json that contains a list of errors by key
export const concatenateAllErrors = errorsByField => {
  if (!errorsByField) {
    return null;
  }

  const result = [];
  for (const field in errorsByField) {
    const errors = errorsByField[field];

    for (const errorMessage of errors) {
      result.push(errorMessage);
    }
  }

  return result.join('\n');
};

export const formatNumber = (number, currency = false) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    style: currency ? 'currency' : 'decimal',
    currency: 'USD',
  }).format(number);
};
